<template>
  <router-view />
</template>

<style>
:root {
  --background-color: #FFFFFF;
  --primary-color: rgb(4, 77, 94);
  --secondary-color: rgb(249, 202, 167);
  --text-color: #000000;
  --padding-mobile-desktop: 50px;
  --padding-large-desktop: 10%;
  --padding-4k-desktop: 25%;
}

* {
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}
</style>
