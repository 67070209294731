module.exports = {
  async sendLeadEmail(body) {
    const result = await fetch(`${process.env.VUE_APP_SERVER_BASE_URL}/emails`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    });

    return result.json();
  },
};
