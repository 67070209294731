<template>
    <nav class="navbar-desktop">
        <div class="left-navbar">
            <img class="logo" src="../assets/logo.webp" alt="bram brokerage logo" rel="preload" width="57px" height="50px">
            <p class="company-name">Bram Brokerage</p>
        </div>
        <button class="quote-btn plausible-event-name=contact+us+btn+click" type="button" @click="handleLanguage">{{
            translate('nav_cta') }}</button>
    </nav>
</template>

<script>
import { scrollToSection, translate } from '../utils/helper';

export default {
  data() {
    return {
      scrollToSection,
    };
  },

  methods: {
    translate,
    handleLanguage() {
      this.$i18n.locale = this.$i18n.locale === 'es' ? 'en' : 'es';
      this.$router.replace('/');
    },
  },
};

</script>

<style scoped>
.navbar-desktop {
    align-items: center;
    background-color: transparent;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px var(--padding-mobile-desktop) 50px var(--padding-mobile-desktop);
    width: 100%;
}

.left-navbar {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo {
    height: 50px;
    position: relative;
    top: 5px;
    width: 57px;
}

.links {
    align-content: center;
    display: flex;
    justify-content: space-evenly;
}

.links a {
    color: #FFFFFF;
    margin-right: 10px;
    text-decoration: none;
}

.links a:hover {
    color: #000000;
}

.company-name {
    font-size: 40px;
    font-weight: 700;
    margin: 0;
}

.quote-btn {
    background-color: #FFFFFF;
    border-radius: 7px;
    border: none;
    font-weight: 600;
    height: 36px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
}

.quote-btn:hover {
    background-color: var(--secondary-color);
    cursor: pointer;
    transition: 0.3s;
}

/* mobile + tablet */
@media screen and (max-width: 992px) {
    .company-name {
        font-size: 25px;
    }
}

/* large screen */
@media screen and (min-width: 1700px) and (max-width: 2000px) {
    .navbar-desktop {
        padding-left: var(--padding-large-desktop);
        padding-right: var(--padding-large-desktop);
    }
}

/* 4k screens*/
@media screen and (min-width: 2000px) {
    .navbar-desktop {
        padding-left: var(--padding-4k-desktop);
        padding-right: var(--padding-4k-desktop);
    }
}
</style>
