<template>
    <section class="reviews-container">
        <h2 class="header">{{ translate('reviews_title') }}</h2>
        <div class="reviews">
            <div class="card first">
                <div class="avatar">O</div>
                <p class="title">Oliver Liranzo</p>
                <p class="company">New York, NY</p>
                <p class="review">I highly recommend Beany from Bram Brokerage for her prompt service. In my quest for new
                    plates, she efficiently identified an issue with the title and swiftly contacted the seller to secure
                    the correct one. Thanks to her expertise, I received my new plates hassle-free.</p>
            </div>
            <div class="card second">
                <div class="avatar">W</div>
                <p class="title">Wilson Miranda</p>
                <p class="company">Flushing, NY</p>
                <p class="review">Exceptional DMV service from Bram Brokerage! I'm impressed with their accuracy and
                    commitment. Beyond efficiently handling paperwork, they went the extra mile when I faced missing
                    documents from the dealer. Bram Brokerage is highly recommended for their outstanding and
                    customer-focused approach to DMV services.</p>
            </div>
            <div class="card third">
                <div class="avatar">G</div>
                <p class="title">Gerson Castro</p>
                <p class="company">Bronx, NY</p>
                <p class="review">Bram Brokerage nos brindo un servicio excepcional para nuestra Aplicacion de vehiculo
                    salvage y tambien con la Inspeccion, teniendo siempre una respuesta a todas las preguntas con mucha
                    paciencia. La comunicacion fue transparente y ponen ante todo la satisfaccion del cliente , su
                    profesionalidad y experiencia fue evidente y de ahora en adelante siempre seran mi primera opcion para
                    todos mis futuros trabajos con DMV.</p>
            </div>
            <div class="card four">
                <div class="avatar">F</div>
                <p class="title">Fidencio Ortega</p>
                <p class="company">Bronx, NY</p>
                <p class="review">Gracias a Bram Brokerage por ser sorprendentemente rápidos y consistente en todo lo que
                    hacen para personas como yo que no tenemos tiempo para ir al DMV, Beanys hizo todo por mi y hasta me
                    trajo mis placas y registracion hasta la puerta de mi casa. </p>
            </div>
        </div>
    </section>
</template>

<script>
import { translate } from '../utils/helper';

export default {
  methods: {
    translate,
  },
};
</script>

<style scoped>
.reviews-container {
    padding: var(--padding-mobile-desktop);
    background-color: var(--secondary-color);
}

.avatar {
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: 50%;
    display: flex;
    font-size: 50px;
    height: 77px;
    justify-content: center;
    width: 77px;
}

.card {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 25px 50px 25px 50px;
    text-align: justify;
    transition: all .1s linear;
    border: none;
}

.card:hover {
    cursor: pointer;
    transform: translateY(-5px);
}

.reviews {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 50px;
}

.header {
    font-weight: 700;
    text-align: center;
}

.title {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.review::before,
.review::after {
    content: '"';
}

.first {
    flex: calc(40% - 50px);
}

.second {
    flex: calc(60% - 50px);
}

.third {
    flex: calc(60% - 50px);
}

.four {
    flex: calc(40% - 50px);
}

/* mobile */
@media screen and (max-width: 800px) {

    .first,
    .second,
    .third,
    .four {
        flex: 100%;
    }

    .review {
        display: block;
    }

}

/* tablet */
@media screen and (min-width: 801px) and (max-width: 1024px) {

    .first,
    .second,
    .third,
    .four {
        flex: calc(50% - 25px);
    }
}

/* large screen */
@media screen and (min-width: 1700px) and (max-width: 2000px) {
    .reviews-container {
        padding-left: var(--padding-large-desktop);
        padding-right: var(--padding-large-desktop);
    }
}

/* 4k screens*/
@media screen and (min-width: 2000px) {
    .reviews-container {
        padding-left: var(--padding-4k-desktop);
        padding-right: var(--padding-4k-desktop);
    }
}
</style>
