<template>
    <header class="header-container">
        <div class="left">
            <p class="title">{{ translate('header_title') }}</p>
            <p class="description">{{ translate('header_description') }}</p>
            <div class="btn-container">
                <button class="quote-btn btn plausible-event-name=get+on+the+road+faster+btn+clicks"
                    @click="scrollToSection('contact')">{{ translate('header_quote_cta') }}</button>
                <button class="learn-more-btn btn plausible-event-name=Learn+more+btn+click"
                    @click="scrollToSection('services')">{{ translate('header_secondary_cta') }}</button>
            </div>
        </div>
        <div class="right">
            <img class="license" src="../assets/dmv-assets.webp" alt="new york state title, registration, plate"
                rel="preload" fetchpriority="high" width="100%" height="100%">
        </div>
    </header>
</template>

<script>
import { scrollToSection, translate } from '../utils/helper';

export default {
  data() {
    return {
      scrollToSection,
    };
  },

  methods: {
    translate,
  },
};

</script>

<style scoped>
.header-container {
    background-color: transparent;
    display: flex;
    padding: 0 var(--padding-mobile-desktop) 50px var(--padding-mobile-desktop);
}

.left {
    flex: 1;
    max-width: 100%;
}

.right {
    flex: 1;
    display: flex;
    justify-content: center;
}

.title {
    background-color: var(--secondary-color);
    border-radius: 7px;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
    padding: 5px;
    text-align: center;
    width: fit-content;
}

.services {
    text-decoration: underline;
}

.description {
    color: #FFFFFF;
    line-height: 1.6;
    margin-bottom: 30px;
}

.btn-container {
    display: flex;
    gap: 26px;
}

.btn {
    border-radius: 7px;
    border: none;
    font-weight: 600;
}

.quote-btn {
    background-color: #FFFFFF;
}

.btn:hover {
    background-color: var(--secondary-color);
    cursor: pointer;
    transition: 0.3s;
}

.learn-more-btn {
    background-color: transparent;
    border: solid 1px #FFFFFF;
    color: #FFFFFF;
}

.license {
    height: 100%;
    width: 100%;
    max-height: 484.65px;
    max-width: 624px;
}

/* mobile */
@media screen and (max-width: 450px) {
    .btn-container {
        flex-direction: column;
        gap: 20px;
    }
}

/* tablet */
@media screen and (max-width: 1024px) {
    .right {
        display: none;
    }

    .title {
        text-align: center;
    }
}

/* large screen */
@media screen and (min-width: 1700px) and (max-width: 2000px) {
    .header-container {
        padding-left: var(--padding-large-desktop);
        padding-right: var(--padding-large-desktop);
    }
}

/* 4k screens*/
@media screen and (min-width: 2000px) {
    .header-container {
        padding-left: var(--padding-4k-desktop);
        padding-right: var(--padding-4k-desktop);
    }
}
</style>
