<template>
    <section class="contact-container" id="contact">
        <section class="info">
            <h2 class="header">{{ translate('contact_title') }}</h2>
            <p class="sub-header">{{ translate('contact_description') }}</p>

            <div class="isolated-area email-phone">
                <div class="contact-icon-container">
                    <div class="icon">
                        <i class="fas fa-envelope fa-lg"></i>
                    </div>
                    <div>
                        <p class="contact-info-header">{{ translate('contact_email') }}</p>
                        <p><a href="mailto:banziani@brambrokerage.com">banziani@brambrokerage.com</a></p>
                    </div>
                </div>
                <div class="contact-icon-container">
                    <div class="icon">
                        <i class="fas fa-phone fa-lg"></i>
                    </div>
                    <div>
                        <p class="contact-info-header">{{ translate('contact_phone') }}</p>
                        <p><a href="tel:+17185870602">718-587-0602</a></p>
                    </div>
                </div>
                <div class="contact-icon-container">
                    <div class="icon">
                        <i class="fas fa-fax fa-lg"></i>
                    </div>
                    <div>
                        <p class="contact-info-header">{{ translate('contact_fax') }}</p>
                        <p><a href="tel:+17185870601">718-587-0601</a></p>
                    </div>
                </div>
            </div>
        </section>
        <form class="isolated-area plausible-event-name=Messages+Sent" @submit.prevent="handleFormSubmit">
            <input :placeholder="translate('contact_name_placeholder')" type="text" class="fields" required
                v-model="name" />
            <input :placeholder="translate('contact_email_placeholder')" type="email" class="fields"
                v-model="customerEmail" />
            <input :placeholder="translate('contact_phone_placeholder')" type="tel" class="fields" required v-model="number"
                pattern="\d{3}-?\d{3}-?\d{4}"
                title="Please enter a valid phone number ex: (###-###-####) or (##########)" />
            <div class="form-check form-check-inline checkbox-container">
                <div class="form-input-container">
                    <input class="form-check-input" type="radio" value="Plates" name="reason" v-model="service" id="plates">
                    <label class="form-check-label" for="plates">{{ translate('contact_option_plates') }}</label>
                </div>
                <div class="form-input-container">
                    <input class="form-check-input" type="radio" value="Registrations" name="reason" v-model="service"
                        id="registrations">
                    <label class="form-check-label" for="registrations">{{ translate('contact_option_registrations')
                    }}</label>
                </div>
                <div class="form-input-container">
                    <input class="form-check-input" type="radio" value="Titles" name="reason" v-model="service" id="titles">
                    <label class="form-check-label" for="titles">{{ translate('contact_option_titles') }}</label>
                </div>
            </div>

            <textarea :placeholder="translate('contact_message_placeholder')" required v-model="message"></textarea>
            <div class="btn-container">

                <button type="submit"> {{ translate('contact_submit_btn') }}</button>
            </div>
        </form>
    </section>
</template>

<script>
import { useToast } from 'vue-toastification';
import api from '../utils/api';
import { translate } from '../utils/helper';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      name: '',
      customerEmail: '',
      number: '',
      service: '',
      message: '',
    };
  },

  methods: {
    translate,
    clearForm() {
      this.name = '';
      this.customerEmail = '';
      this.number = '';
      this.service = '';
      this.message = '';
    },
    async handleFormSubmit() {
      try {
        const data = await api.sendLeadEmail({
          name: this.name, customerEmail: this.customerEmail, number: this.number, service: this.service, message: this.message, recipientEmail: 'michellbp@msn.com',
        });
        if (data.status === 200) {
          this.toast.success('Request sent!');
          this.clearForm();
          return;
        }
        this.toast.error('Request failed!');
      } catch (_) {
        this.toast.error('Request failed!');
      }
    },
  },
};
</script>

<style scoped>
.contact-container {
    display: flex;
    gap: 150px;
    padding: var(--padding-mobile-desktop);
    background-color: var(--primary-color);
}

.info {
    flex: 1;
}

.contact-icon-container {
    display: flex;
    gap: 18px;
}

.header {
    font-weight: 400;
    margin-bottom: 23px;
    color: #FFFFFF;
}

.sub-header {
    margin-top: 45px;
    margin-bottom: 45px;
    color: #FFFFFF;
}

.icon {
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: 50%;
    color: #000000;
    display: flex;
    height: 56px;
    justify-content: center;
    width: 56px;
}

.contact-info-header {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
}

.isolated-area {
    background-color: #FFFFFF;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 25px;
    padding: 23px;
}

.fields {
    border-radius: 3px;
    border: 1.178px solid rgba(0, 0, 0, 0.19);
    height: 50px;
    padding: 20px;
}

.form-check-label {
    margin-right: 20px;
}

::placeholder {
    color: #000000;
}

textarea {
    border-radius: 3px;
    border: 1.178px solid rgba(0, 0, 0, 0.19);
    height: 177px;
    padding: 20px;
    resize: none;
}

.btn-container {
    align-items: center;
    display: flex;
    height: 36px;
    justify-content: center;
    width: 100%;
}

button {
    background-color: var(--secondary-color);
    border-radius: 14px;
    border: none;
    height: 100%;
    width: 300px;
}

a {
    color: #000000;
}

/* mobile */
@media screen and (max-width: 900px) {
    .contact-container {
        flex-direction: column;
        gap: 0;
    }

    .email-phone {
        display: none;
    }

    .checkbox-container {
        display: flex;
        flex-direction: column;
    }

    .form-input-container {
        width: 100%;
    }
}

/* large screen */
@media screen and (min-width: 1700px) and (max-width: 2000px) {
    .contact-container {
        padding-left: var(--padding-large-desktop);
        padding-right: var(--padding-large-desktop);
    }
}

/* 4k screens*/
@media screen and (min-width: 2000px) {
    .contact-container {
        padding-left: var(--padding-4k-desktop);
        padding-right: var(--padding-4k-desktop);
    }
}
</style>
