<template>
    <footer class="footer-container">
        <div class="contact-us-container">
            <p class="header">{{ translate('footer_title') }}</p>
            <p><span><i class="fas fa-envelope fa-lg icon"></i></span><a
                    href="mailto:banziani@brambrokerage.com">banziani@brambrokerage.com</a></p>
            <p><span><i class="fas fa-phone fa-lg icon"></i></span><a href="tel:+17185870602">718-587-0602</a></p>
            <p><span><i class="fas fa-fax fa-lg icon"></i></span><a href="tel:+17185870601">718-587-0601</a></p>
        </div>
        <div class="disclaimer-container">
            <p class="header">{{ translate('footer_disclaimer') }}</p>
            <p>{{ translate('footer_disclaimer_description_1') }}</p>
            <p>{{ translate('footer_disclaimer_description_2') }}</p>
        </div>
        <div class="office-hours-container">
            <p class="header">{{ translate('footer_office_hours') }}</p>
            <p>{{ translate('footer_office_mon_fri') }}</p>
            <p>{{ translate('footer_office_sat_sun') }}</p>
        </div>
    </footer>
</template>

<script>
import { translate } from '../utils/helper';

export default {
  methods: {
    translate,
  },
};
</script>

<style scoped>
.footer-container {
    display: flex;
    gap: 150px;
    padding: var(--padding-mobile-desktop);
    background-color: #000000;
    color: #FFFFFF;
    justify-content: space-between;
}

a {
    color: #FFFFFF;
}

.icon {
    margin-right: 15px;
}

.header {
    color: var(--secondary-color);
    font-weight: 600;
}

.disclaimer-container {
    max-width: 500px;
}

/* mobile */
@media screen and (max-width: 900px) {
    .footer-container {
        gap: 50px;
        flex-direction: column;
    }
}

@media screen and (min-width: 901px) and (max-width: 1439px) {
    .footer-container {
        gap: 50px;
    }

    .contact-us-container,
    .disclaimer-container,
    .office-hours-container {
        flex: 1;
    }
}

/* large screen */
@media screen and (min-width: 1700px) and (max-width: 2000px) {
    .footer-container {
        padding-left: var(--padding-large-desktop);
        padding-right: var(--padding-large-desktop);
    }
}

/* 4k screens*/
@media screen and (min-width: 2000px) {
    .footer-container {
        padding-left: var(--padding-4k-desktop);
        padding-right: var(--padding-4k-desktop);
    }
}
</style>
