<template>
  <div class="section-nav-header">
    <Nav />
    <Header />
  </div>
  <Services />
  <Reviews />
  <Contact />
  <Footer />
</template>

<script>
import Contact from '@/components/Contact.vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import Nav from '@/components/Nav.vue';
import Reviews from '@/components/Reviews.vue';
import Services from '@/components/Services.vue';

export default {
  name: 'Home',

  components: {
    Contact,
    Footer,
    Header,
    Nav,
    Reviews,
    Services,
  },

  beforeMount() {
    const lang = this.$route.params.lang === 'es' ? 'es' : 'en';
    this.$i18n.locale = lang;

    if (this.$route.params.lang !== 'es' && this.$route.params.lang !== 'en') {
      this.$router.replace('/');
    }
  },
};
</script>

<style scoped>
.section-nav-header {
  background-color: var(--primary-color);
  background-image: url("https://www.transparenttextures.com/patterns/60-lines.png");
}
</style>
