<template>
    <section class="services-container" id="services">
        <h2 class="header">{{ translate('services_title') }}</h2>
        <div class="cards">
            <div class="card">
                <img src="../assets/plates.webp" class="card-img-top" alt="new york state plates" rel="preload" width="100%"
                    height="285px">
                <div class="card-body">
                    <h3 class="card-title">{{ translate('services_plates_title') }}</h3>
                    <p class="card-text">{{ translate('services_plates_description') }}</p>
                </div>
            </div>
            <div class="card">
                <img src="../assets/registration.webp" class="card-img-top" alt="new york state registration" rel="preload"
                    width="100%" height="285px">
                <div class="card-body">
                    <h3 class="card-title">{{ translate('services_registrations_title') }}</h3>
                    <p class="card-text">{{ translate('services_registrations_description') }}</p>
                </div>
            </div>
            <div class="card">
                <img src="../assets/title.webp" class="card-img-top" alt="new york state title" rel="preload" width="100%"
                    height="285px">
                <div class="card-body">
                    <h3 class="card-title">{{ translate('services_titles_title') }}</h3>
                    <p class="card-text">{{ translate('services_titles_description') }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { translate } from '../utils/helper';

export default {
  methods: {
    translate,
  },
};
</script>

<style scoped>
.services-container {
    padding: var(--padding-mobile-desktop);
}

.header {
    text-align: center;
    font-weight: 700;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    margin-top: 50px;
}

.card {
    flex: calc(33.33% - 50px);
    border-radius: 12px;
    background: #FFFFFF;
    border: 1.178px solid rgba(0, 0, 0, 0.19);
}

.card-title {
    text-align: center;
}

.card-img-top.card-img-top {
    border-radius: 20px;
    padding: 10px;
    height: 265px;
}

/* mobile */
@media screen and (max-width: 800px) {
    .cards {
        align-items: center;
        flex-direction: column;
    }

    .card-img-top.card-img-top {
        object-fit: cover;
    }
}

/* tablet */
@media screen and (min-width: 801px) and (max-width: 1024px) {
    .cards {
        flex-wrap: wrap;
    }

    .card {
        flex: calc(50% - 50px);
        max-width: 435px;
    }
}

/* large screen */
@media screen and (min-width: 1700px) and (max-width: 2000px) {
    .services-container {
        padding-left: var(--padding-large-desktop);
        padding-right: var(--padding-large-desktop);
    }
}

/* 4k screens*/
@media screen and (min-width: 2000px) {
    .services-container {
        padding-left: var(--padding-4k-desktop);
        padding-right: var(--padding-4k-desktop);
    }
}
</style>
