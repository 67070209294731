module.exports = {
  scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    window.scrollTo({
      top: section.offsetTop,
      behavior: 'smooth',
    });
  },
  translate(key) {
    return this.$i18n.messages[this.$i18n.locale][key];
  },
};
